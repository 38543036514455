.project-item {
	list-style: none;
}

.project-header {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.project-logo {
	width: 30px;
	height: 30px;
	object-fit: contain;
	border-radius: 4px;
}

.project-details {
	flex: 1;
}

.project-main-info {
	font-size: 1rem;
	display: flex;
	align-items: center;
}

.project-blurp {
	color: #555;
	font-size: 0.9rem;
}

.project-tools {
	color: #666;
	font-size: 0.9rem;
}

.project-toggle {
	margin-top: 0.5rem;
	cursor: pointer;
	background: none;
	border: none;
	color: #007bff;
	font-size: 0.9rem;
}

.project-description {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.4s ease, padding 0.4s ease;
	padding: 0 1rem;
}

.project-description.expanded {
	max-height: 500px; /* Adjust based on content */
	padding: 1rem;
}

.project-links {
	margin-top: 0.5rem;
}

.project-links a {
	color: #007bff;
	text-decoration: none;
	margin-right: 1rem;
}

.project-links a:hover {
	text-decoration: underline;
}
