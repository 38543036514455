.talks-papers-item {
	list-style: none;
}

.talks-papers-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.talks-papers-title-type {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.talks-papers-type {
	background-color: #f0f0f0;
	color: #555;
	padding: 0.2rem 0.5rem;
	border-radius: 4px;
	font-size: 0.9rem;
}

.talks-papers-location {
	color: #666;
	font-size: 0.9rem;
}

.talks-papers-toggle {
	margin-top: 0.5rem;
	cursor: pointer;
	background: none;
	border: none;
	color: #007bff;
	font-size: 0.9rem;
}

.talks-papers-description {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.4s ease, padding 0.4s ease;
	padding: 0 1rem;
}

.talks-papers-description.expanded {
	max-height: 300px;
	padding: 1rem;
}

.talks-papers-link a {
	color: #007bff;
	text-decoration: none;
}

.talks-papers-link a:hover {
	text-decoration: underline;
}
