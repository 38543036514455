.award-item {
	list-style: none;
}

.award-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.award-left {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.award-left > strong {
	font-size: 1rem;
}

.award-extra {
	margin-left: 0.5rem;
	color: #555;
	font-size: 0.9rem;
}

.award-issuer {
	color: #666;
	font-size: 0.9rem;
}

.award-year {
	color: #666;
	font-size: 0.9rem;
}

.award-toggle {
	margin-top: 0.5rem;
	cursor: pointer;
	background: none;
	border: none;
	color: #007bff;
	font-size: 0.9rem;
}

.award-description {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.4s ease, padding 0.4s ease;
	padding: 0 1rem;
}

.award-description.expanded {
	max-height: 300px;
	padding: 1rem;
}

.award-link a {
	color: #007bff;
	text-decoration: none;
}

.award-link a:hover {
	text-decoration: underline;
}
