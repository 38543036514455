.App {
	width: 100%;
	margin: 0 auto;
}

@media (min-width: 1200px) {
	.App {
		max-width: 60%;
	}
}
@media (max-width: 1199px) and (min-width: 769px) {
	.App {
		max-width: 90%;
	}
}
@media (max-width: 768px) {
	.App {
		max-width: 100%;
	}
}

/* section {
	padding-left: 1rem;
	padding-right: 1rem;
} */

@media (max-width: 768px) {
	section {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

section {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.header-title {
	font-size: 1.25rem;
	padding-bottom: 1rem;
}

.intro-text {
	font-family: var(--font-tertiary);
}

.code-snippet {
	font-family: var(--font-mono);
}

.profile-description {
	font-family: var(--font-sans);
}

img {
	max-width: 100%;
	height: auto;
}

.profile-header {
	display: flex;
	justify-content: center;
	align-items: space-between;
}

.header-content {
	display: flex;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
}

.text-content {
	flex: 1;
}

.profile-image-container {
	margin-left: auto;
}

.profile-pic {
	width: 125px;
	height: 125px;
	object-fit: cover;
	border: 1px solid #999;
	border-radius: 4px;
	margin: 1rem 0;
}
