/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@500&family=Michroma&family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300;400;500;600;700;800;900&display=swap');

/* Setting up CSS variables for easy font switching */
:root {
	--font-primary: 'Darker Grotesque', sans-serif;
	--font-secondary: 'Michroma', sans-serif;
	--font-tertiary: 'Raleway', sans-serif;
	--font-mono: 'IBM Plex Mono', monospace;
	--font-sans: 'IBM Plex Sans', sans-serif;
}

/* Basic Reset */
* {
	padding: 0;
	margin: 0;
	font-family: var(--font-primary);
	font-size: 16px;
	box-sizing: border-box;

	/* Hide Scrollbars */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
	display: none;
}

/* Example Usage */
body {
	font-family: var(--font-primary);
}
