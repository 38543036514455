.education-item {
	list-style: none;
}

.education-header {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.education-logo {
	width: 25px;
	height: 25px;
	object-fit: contain;
	border-radius: 4px;
}

.education-details {
	flex: 1;
}

.education-main-info {
	display: flex;
	justify-content: space-between;
	font-size: 1rem;
}

.education-degree-location {
	display: flex;
	justify-content: space-between;
	color: #666;
	* {
		font-size: 0.85rem;
	}
}

.education-program {
	margin-top: 0.5rem;
	color: #555;
}

.education-toggle {
	margin-top: 0.5rem;
	cursor: pointer;
	background: none;
	border: none;
	color: #007bff;
	font-size: 0.9rem;
}

.education-description {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.4s ease, padding 0.4s ease;
	padding: 0 1rem;
}

.education-description.expanded {
	max-height: 500px; /* Adjust this based on the content height */
	padding: 1rem;
}

.education-courses {
	list-style-type: disc;
	padding-left: 1.5rem;
}

.education-website a {
	color: #007bff;
	text-decoration: none;
}

.education-website a:hover {
	text-decoration: underline;
}
